
#game-end-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 3rem;
    background: url("/images/pine.png");
}


#game-end-screen h4,
#game-end-screen div
{ display: inline-block; }

#game-end-screen .flex-vertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#game-end-screen .flex-horizontal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


#numbers-wrapper {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

#numbers-wrapper > div {
    margin: 0 1rem;
}

#numbers-wrapper > div:nth-child(2) {
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    padding: 2rem 0;
}

#numbers-wrapper .number-display {
    margin: 0 0.5rem;
    text-align: center;
}

#points-box {
    border: solid 1px black;
    min-width: 6rem;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
}

#points-box + div {
    margin-top: 1rem;
}

#numbers-wrapper h4 {
    margin-bottom: 0.5rem;
}

#numbers-wrapper h4 + div .number-display span {
    font-size: 2rem;
}

#numbers-wrapper h4 + div .number-display .small {
    font-size: 1.1rem
}


#points-stat-number {
    font-size: 4rem;
}
#boards-stat-number {
    font-size: 2rem;
}
#accuracy-stat-number {
    font-size: 2rem;
}


#good-job-text {
    margin: 1rem;
}


#level-meter-container {
    position: relative;
    padding: 3rem;
    padding-bottom: 1rem;
}
#level-meter-container img {
    max-height: 2rem;
    vertical-align: bottom;
}
#level-meter {
    height: 1rem;
    width: 20rem;
    position: relative;
    background: linear-gradient(90deg, #00DC16 0%, #F6FB04 50%, #FFA800 100%);
}
#level-meter-needle {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -100%);

    padding-bottom: 0.5rem;

    transition: left 1s;
}
#level-meter-needle span {
    font-size: 0.8rem;
    font-weight: bold;
}
#level-meter-needle::after {
    content: "";
    height: 1rem;
    width: 2px;
    background: red;
    position: absolute;
    left: 50%;
    bottom: -1rem;
}

#good-job-text {
    font-size: 1.3rem;
}

#game-end-discussion img {
    max-height: 15vh;
    max-width: 15vw;
    margin: 1rem;
}
#game-end-discussion-text-wrapper p {
    max-width: 30ch;
    font-size: 1.1rem;
    align-self: flex-start;
}

div#game-end-screen-buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#game-end-screen .button {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin: 0.8rem 0.8rem;
    text-align: center;
}

.button#play-again-button {
    font-size: 1.5rem;
    padding: 1rem 4rem;
    margin-bottom: 0.8rem;
}

